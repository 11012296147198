import React from 'react';
import st from './published.module.scss';

export const Published = ({date}) => {
  return (
    <div className={st.wrapper}>
      <div className={st.icon}>
        <img src={'/calendar.png'} alt='calendar' />
      </div>

      <div className={st.text}>
        <div className={st.text__title}>Published</div>
        <div className={st.text__date}>{date}</div>
      </div>
    </div>
  );
};
