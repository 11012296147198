import {SizeProp} from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import st from './blog-button.module.scss';

export const BlogButton = ({
  side = 'next',
  className,
  onClick,
}: {
  side?: 'prev' | 'next';
  className?: string;
  size?: SizeProp;
  onClick?: () => void;
}) => {
  return (
    <div className={`${st.wrapper} ${className}`} onClick={onClick}>
      <div className={`${st.blog__button} ${st[side]}`}></div>
    </div>
  );
};
