import React, {useState} from 'react';
import {privacyPolicyUrl, portalId, bookFormId} from '../../../api';
import {Button} from '../../../components/button/button';
import st from './hubspot.module.scss';
import {ErrorTypes} from '../../join-us/form/form';

export const Hubspot = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState({});
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [missingInput, setMissingInput] = useState([]);

  const submitForm = e => {
    if (e) e.preventDefault();
    const missingFields = [];
    if (!email) {
      missingFields.push('email');
    }

    if (missingFields.length > 0) {
      setMissingInput(missingFields);
      return;
    }

    const isBrowser = typeof window !== 'undefined';
    const pageUri = isBrowser ? window.location.href : null;
    const pageName = isBrowser ? document.title : null;
    const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${bookFormId}`;

    setError({});
    setSuccess(false);
    setMissingInput([]);
    setLoading(true);
    const body = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'firstname',
          value: firstName,
        },
        {
          name: 'email',
          value: email,
        },
      ],
      context: {
        pageUri,
        pageName,
      },
    };

    const payload = JSON.stringify(body);
    const xhr = new XMLHttpRequest();

    xhr.open('POST', postUrl);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
        setSuccess(true);
        setFirstName('');
        setEmail('');
        setLoading(false);
      } else if (xhr.readyState == 4 && [400, 403, 404].includes(xhr.status)) {
        if (JSON.parse(xhr.response)?.errors?.map(error => error.errorType === ErrorTypes.Email)) {
          setError({email: 'Invalid email address.'});
        } else {
          setError({form: 'We had some issues with submission. Try again later.'});
        }
        setSuccess(false);
        setLoading(false);
      }
    };

    // Set data for analitics.
    if (isBrowser) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `emailSignup`,
      });
    }

    // Sends the request
    xhr.send(payload);
  };

  return (
    <div className={st.wrapper}>
      <div className={st.wrapper_content}>
        <h2 className={st.title}>Use IT Staffing in your favor with free "IT Staff Augmentation Guide"</h2>
        <form id={'form'} className={st.form}>
          <div className={st.inputs}>
            <div className={st.input}>
              <input
                placeholder={'Your name'}
                type={'text'}
                className={setMissingClass(missingInput, 'firstName', st.required)}
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
            </div>
            <div className={st.input}>
              <input
                placeholder={'Work email'}
                type={'email'}
                className={`${setMissingClass(missingInput, 'email', st.required)} ${error.email && st.required}`}
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className={st.footer}>
            <div className={st.consent}>
              By submitting your email you agree to receive the content requested and to Blue House Technologies'{' '}
              <a className={st.link} href={privacyPolicyUrl} rel='noreferrer' target='_blank'>
                privacy policy.
              </a>
            </div>
            <div className={st.buttons}>
              <Button stClassName={st.button} disabled={loading || success} onClick={submitForm}>
                {success ? 'Submitted' : loading ? 'Sending' : 'Download'}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const setMissingClass = (missingItems, item, className) => (missingItems.includes(item) ? className : '');
