import React, {Fragment} from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import {Divider} from '../../../components/divider/divider';
import st from './popular.module.scss';
import {mark} from '../../../utils/marked';
import {Link} from 'gatsby';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {BlogButton} from '../../../components/blog-button/blog-button';

const responsive = {
  desktop: {
    breakpoint: {max: 3000, min: 0},
    items: 1,
  },
};

const CustomRightArrow = props => {
  return <BlogButton {...props} className={st.button__right} />;
};

const CustomLeftArrow = props => {
  return <BlogButton {...props} className={st.button__left} side={'prev'} />;
};

export const Popular = ({scrollId = '', posts}) => {
  return (
    <div id={scrollId} className={st.popular__wrapper}>
      <div className={st.title}>Popular</div>
      <Divider stClassName={st.divider} />
      <div className={st.slider__wrapper}>
        <Carousel
          responsive={responsive}
          ssr={true}
          infinite={true}
          removeArrowOnDeviceType={['mobile']}
          className={st.carousel}
          customRightArrow={<CustomRightArrow />}
          customLeftArrow={<CustomLeftArrow />}
          autoPlay={true}
          autoPlaySpeed={5000}
        >
          {posts.map((post, index) => (
            <Fragment key={index}>
              <Link to={`/blog/${post.url}/`}>
                <div
                  className={st.image__wrapper}
                  style={{backgroundImage: `url(${post.Thumbnail_img.publicURL})`}}
                ></div>
              </Link>
              <div className={st.tiles}>
                {post.Categories.map(category => (
                  <div key={index} className={st.tile}>
                    {category.name}
                  </div>
                ))}
              </div>
              <Link to={`/blog/${post.url}/`}>
                <h2 className={st.title} dangerouslySetInnerHTML={mark(post.Title)}></h2>
              </Link>
              <div className={st.description} dangerouslySetInnerHTML={mark(post.Thumbnail_description)}></div>
            </Fragment>
          ))}
          {posts.map((post, index) => (
            <Fragment key={index}>
              <Link to={`/blog/${post.url}/`}>
                <div
                  className={st.image__wrapper}
                  style={{backgroundImage: `url(${post.Thumbnail_img.publicURL})`}}
                ></div>
              </Link>
              <div className={st.tiles}>
                {post.Categories.map(category => (
                  <div key={index} className={st.tile}>
                    {category.name}
                  </div>
                ))}
              </div>
              <Link to={`/blog/${post.url}/`}>
                <div className={st.title} dangerouslySetInnerHTML={mark(post.Title)}></div>
              </Link>
              <div className={st.description} dangerouslySetInnerHTML={mark(post.Thumbnail_description)}></div>
            </Fragment>
          ))}
        </Carousel>
      </div>
    </div>
  );
};
