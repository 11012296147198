import React from 'react';
import st from './header.module.scss';

export const Header = ({className = '', categories, selectedCategory, setSelectedCategory}) => {
  return (
    <div className={`${st.header} ${className}`}>
      <div className={st.categories__wrapper}>
        <div className={st.categories__title}>Categories:</div>
        <div className={st.categories__tiles}>
          {categories.map((category, index) => (
            <Tile
              key={`${category.slug} ${index}`}
              category={category}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export const Tile = ({category, setSelectedCategory, selectedCategory}) => (
  <div
    className={`${st.tile} ${selectedCategory === category.slug ? st.active : ''}`}
    onClick={() => setSelectedCategory(category.slug)}
  >
    {category.name}
  </div>
);
