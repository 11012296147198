import {graphql} from 'gatsby';
import React from 'react';
import {Layout} from '../components/layout/layout';
import {Blog} from '../pages-components/blog/blog';
const BlogPage = ({data}) => {
  return (
    <Layout>
      <Blog data={data} />
    </Layout>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query BlogPostQuery {
    allStrapiBlogPost {
      edges {
        node {
          id
          Author
          Author_description
          Author_avatar {
            publicURL
          }
          Content
          Categories {
            id
            name
            slug
          }
          Popular
          Date
          time_to_read
          visible
          Title
          Thumbnail_description
          Thumbnail_img {
            publicURL
          }
          url
        }
      }
    }
    allStrapiBlogCategory {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
  }
`;
