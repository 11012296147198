import React, {useEffect, useState} from 'react';
import {BlogButton} from '../../../components/blog-button/blog-button';
import {Published} from '../../../components/published/published';
import {Time} from '../../../components/time/time';
import {mark} from '../../../utils/marked';
import st from './posts.module.scss';
import ReactPaginate from 'react-paginate';
import './overwrite.scss';
import {Link} from 'gatsby';
import {mediaQueries, useWindowWidth} from '../../../utils/width';
import scrollTo from 'gatsby-plugin-smoothscroll';

export const Posts = ({scrollId = '', posts}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [visiblePosts, setVisiblePosts] = useState([]);
  const width = useWindowWidth();
  const totalPosts = posts.length;
  const [pageLimit, setPageLimit] = useState(6);
  useEffect(() => {
    if (width < mediaQueries.phone) {
      setPageLimit(3);
    } else if (width < mediaQueries.tabLand) {
      setPageLimit(4);
    }
  }, [width]);

  const totalPages = Math.ceil(totalPosts / pageLimit) || 1;

  useEffect(() => {
    const newPosts = posts;
    setVisiblePosts(newPosts.slice(currentPage * pageLimit, currentPage * pageLimit + pageLimit));

    if (Math.ceil(totalPosts / pageLimit) < currentPage + 1) {
      setCurrentPage(0);
    }
  }, [posts, currentPage, pageLimit]);
  const handlePageClick = ({selected}) => {
    if (scrollId) {
      scrollTo(`#${scrollId}`, 'nearest');
    }

    setCurrentPage(selected);
  };

  return (
    <div className={st.section}>
      <div className={st.card__wrapper}>
        {visiblePosts.map(post => (
          <Card key={post.id} post={post} />
        ))}
      </div>
      <div className={st.pagination__wrapper}>
        <ReactPaginate
          previousLabel={<BlogButton side={'prev'} className={currentPage !== 0 ? '' : st.hidden} />}
          nextLabel={<BlogButton side={'next'} className={currentPage !== totalPages - 1 ? '' : st.hidden} />}
          breakLabel={'...'}
          pageCount={totalPages}
          marginPagesDisplayed={0}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName={st.pagination}
          pageClassName={st.page}
          breakClassName={st.page}
          previousClassName={st.prev}
          nextClassName={st.next}
          forcePage={currentPage}
        />
      </div>
    </div>
  );
};

const Card = ({post: {Categories, Date, time_to_read, Title, Thumbnail_description, Thumbnail_img, url}}: any) => (
  <div className={st.card}>
    <Link to={`/blog/${url}/`}>
      <div className={st.image} style={{backgroundImage: `url(${Thumbnail_img?.publicURL})`}} />
    </Link>
    <div className={st.content}>
      <div className={st.tiles}>
        {Categories.map(category => (
          <div key={category.name} className={st.tile}>
            {category.name}
          </div>
        ))}
      </div>
      <Link to={`/blog/${url}/`}>
        <h2 className={st.title} dangerouslySetInnerHTML={mark(Title)}></h2>
      </Link>
      <div className={st.description} dangerouslySetInnerHTML={mark(Thumbnail_description)}></div>
      <div className={st.bottom}>
        <Time time={time_to_read} />
        <div className={st.published}>
          <Published date={Date} />
        </div>

        <Link to={`/blog/${url}/`}>
          <BlogButton className={st.button} />
        </Link>
      </div>
    </div>
  </div>
);
