import React from 'react';
import {Divider} from '../../../components/divider/divider';
import {mark} from '../../../utils/marked';
import st from './big-post.module.scss';
import {BlogButton} from '../../../components/blog-button/blog-button';
import {Time} from '../../../components/time/time';
import {Published} from '../../../components/published/published';
import {Link} from 'gatsby';
export const BigPost = ({post}) => {
  return (
    <div className={st.wrapper}>
      {post ? (
        <>
          <Link to={`/blog/${post.url}/`}>
            <h2 className={st.title}>{post?.Title}</h2>
          </Link>
          <Divider stClassName={st.divider} />
          <div className={st.author}>
            <div className={st.author__image} style={{backgroundImage: `url(${post?.Author_avatar?.publicURL})`}} />
            <div className={st.author__name}>{post?.Author}</div>
          </div>
          <div className={st.categories}>
            {post?.Categories.map((category, index) => (
              <div key={index} className={st.tile}>
                {category.name}
              </div>
            ))}
          </div>
          <Link to={`/blog/${post.url}/`}>
            <div className={st.image} style={{backgroundImage: `url(${post?.Thumbnail_img?.publicURL})`}} />
          </Link>
          <div className={st.description} dangerouslySetInnerHTML={mark(post?.Thumbnail_description)}></div>
          <div className={st.bottom}>
            <div className={st.time}>
              <Time time={post?.time_to_read} />
            </div>
            <div className={st.published}>
              <Published date={post?.Date} />
            </div>
            <div className={st.button}>
              <Link to={`/blog/${post.url}/`}>
                <BlogButton />
              </Link>
            </div>
          </div>
          <Divider stClassName={st.divider__last} />
        </>
      ) : (
        <div>There are no posts in such category</div>
      )}
    </div>
  );
};
