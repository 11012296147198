import React, {useState} from 'react';
import {Header} from './header/header';
import {Divider} from '../../components/divider/divider';
import {BigPost} from './big-post/big-post';
import {Popular} from './popular/popular';
import {Posts} from './posts/posts';
import {Subscribe} from './subscribe/subscribe';
import st from './blog.module.scss';
import {Button} from '../../components/button/button';
import {URLS} from '../../components/nav/nav';
import {Helmet} from 'react-helmet';
import {useStaticQuery, graphql} from 'gatsby';
import {getTime} from '../about/meet-us/meet-us';
import {Hubspot} from './hubspot/hubspot';

const all = {name: 'All', slug: 'all'};
export const Blog = ({data}) => {
  const [selectedCategory, setSelectedCategory] = useState(all.slug);
  const {allStrapiBlogPost, allStrapiBlogCategory} = data;
  const posts = allStrapiBlogPost.edges
    .map(post => post.node)
    .filter(post => post.visible)
    .sort((a, b) => getTime(b.Date) - getTime(a.Date));
  const categories = [all, ...allStrapiBlogCategory.edges.map(category => category.node)];
  const popularPosts = posts.filter(post => post.Popular);

  const [firstPost, ...restPosts] =
    selectedCategory === all.slug
      ? posts
      : posts.filter(post => post.Categories.map(category => category.slug).includes(selectedCategory));
  const {
    restApiBlogPage: {seo},
  } = query();
  return (
    <>
      <Helmet meta={[{name: 'description', content: seo.description}]}>
        <title>{seo.title}</title>
      </Helmet>
      {/*Missing h1 element for the page*/}
      <h1 style={{display: 'none'}}>Articles</h1>
      <div className={st.mobile}>
        <div className={st.mobile__wrapper}>
          <Subscribe />
          <Header
            categories={categories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
          <Divider stClassName={st.divider} />
          <BigPost post={firstPost} />
          <Popular scrollId={'mobile_posts'} posts={popularPosts} />
          <Posts scrollId={'mobile_posts'} posts={restPosts} />
        </div>
      </div>
      <div className={st.desktop}>
        <Header categories={categories} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
        <Divider stClassName={st.divider} />
        <div className={st.wrapper}>
          <div className={st.big__post}>
            <BigPost post={firstPost} />
          </div>
          <div className={st.side__panel}>
            <Subscribe />
            <Popular posts={popularPosts} />
          </div>
        </div>
        <Posts posts={restPosts} />
      </div>
      <Hubspot />
      <Question />
    </>
  );
};

export default Blog;

const Question = () => (
  <div className={st.question}>
    <h2 className={st.title}>Need IT Experts?</h2>
    <Button to={URLS.BUILD} stClassName={st.button}>
      Contact Us
    </Button>
    <div className={st.flaticon}>
      Icons made by{' '}
      <a href='https://www.flaticon.com/authors/srip' title='srip'>
        srip
      </a>{' '}
      from{' '}
      <a href='https://www.flaticon.com/' title='Flaticon'>
        www.flaticon.com
      </a>
    </div>
  </div>
);

const query = () =>
  useStaticQuery(graphql`
    query {
      restApiBlogPage {
        seo {
          title
          description
        }
      }
    }
  `);
