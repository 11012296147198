import React from 'react';
import st from './time.module.scss';

export const Time = ({time}) => {
  return (
    <div className={st.wrapper}>
      <div className={st.icon}>
        <img src={'/wall-clock.png'} alt='wall-clock' />
      </div>
      <div className={st.text}>
        <div className={st.text__title}>Time to read</div>
        <div className={st.text__time}>{time} MIN</div>
      </div>
    </div>
  );
};
